
import { defineComponent } from "vue";
import { SanityBlocks } from "sanity-blocks-vue-component";

export default defineComponent({
  props: {
    client: {
      type: Object,
    },
  },
  components: {
    SanityBlocks,
  },
});
