<template>
  <div
    :class="{
      'content-block': true,
      [`content-block--${content.layout.value}`]: true,
    }"
  >
    <img
      class="content-block-image"
      :src="content.image.asset.url"
      :height="content.image.asset.metadata.dimensions.height"
      :width="content.image.asset.metadata.dimensions.width"
    />
    <ContentBlock :content="content.descriptionRaw" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ContentBlock from "@/components/ContentBlock/ContentBlock.vue";

export default defineComponent({
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {};
  },
  components: {
    ContentBlock,
  },
});
</script>

<style lang="scss" scoped>
.content-block {
  display: flex;
  margin-bottom: 4rem;
  @media (min-width: 76.8rem) {
    margin-bottom: 16rem;
  }
  align-items: flex-start;
}

.content-block-image {
  height: auto;
  border-radius: 0.3rem;
  max-width: 100%;
  @media (min-width: 76.8rem) {
    padding: 2rem;
  }
}

.content-block-text {
  max-width: 45rem;
  position: sticky;
  top: 0;

  @media (min-width: 500px) and (max-width: 76.8rem) {
    max-width: 60rem;
  }
}

.content-block--100I {
  display: flex;
  flex-direction: column;

  .content-block-image {
    max-width: 100%;
  }

  .content-block-text {
    margin: 0;
    text-align: center;
    width: 100%;
    max-width: none;
  }

  ::v-deep(h2) {
    font-size: 1.6rem;
  }
}

h2 {
  font-size: 1.6rem;
}

.content-block--50I50I {
  width: 100%;

  @media (min-width: 76.8rem) {
    width: 50%;
    float: left;
  }

  .content-block-image {
    max-width: 100%;
  }
}

.content-block--50T50I {
  flex-direction: row-reverse;
}

.content-block--50I50T,
.content-block--50T50I {
  @media (max-width: 76.8rem) {
    flex-direction: column;
  }

  .content-block-image {
    @media (min-width: 76.8rem) {
      max-width: 50%;
    }
  }

  .content-block-text {
    margin: 80px auto;
  }
}
</style>
