<template>
  <aside class="client">
    <a
      class="client-logo-link"
      :href="client.url"
      rel="nofollow noopener noreferrer"
    >
      <img
        class="client-logo"
        :src="client.logo"
        :alt="`Logoen til ${client.name}`"
      />
    </a>
    <div>
      <h2 class="client-title">Oppdragsgiver</h2>
      <h3 class="client-name">{{ client.name }}</h3>
      <p class="client-about">
        <SanityBlocks :blocks="client.description" />
      </p>
      <a
        class="client-link"
        :href="client.url"
        rel="nofollow noopener noreferrer"
      >
        Gå til {{ client.name }}
      </a>
    </div>
  </aside>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SanityBlocks } from "sanity-blocks-vue-component";

export default defineComponent({
  props: {
    client: {
      type: Object,
    },
  },
  components: {
    SanityBlocks,
  },
});
</script>

<style lang="scss" scoped>
.client {
  margin: 0 auto;
  display: flex;
  align-items: center;
  max-width: 80rem;
  padding: 4rem;
  background: var(--shx-blue-4);
  border-radius: 0.3rem;

  @media (max-width: 76.8rem) {
    flex-direction: column;
  }
}

.client-logo-link {
  padding: 2rem;
  background: var(--shx-gray-light);
  margin-right: 4rem;
  border-radius: 0.3rem;

  @media (max-width: 76.8rem) {
    display: none;
  }
}

.client-logo {
  width: 15rem;
  height: 15rem;
  padding: 1rem;
}

h2 {
  color: var(--shx-orange);
  font-size: 1.4rem;
  margin: 0;
  line-height: 1;
  text-transform: uppercase;
}

h3 {
  font-size: 2.5rem;
  margin: 0;
  line-height: 1.5;
}
</style>
