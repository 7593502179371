
import { defineComponent } from "vue";
import ProjectPresentationContentBlock from "./ProjectPresentationContentBlock.vue";

export default defineComponent({
  props: {
    content: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    return {};
  },
  components: {
    ProjectPresentationContentBlock,
  },
});
