<template>
  <Spinner v-if="state.isLoading" />
  <Error
    v-if="state.errorMessage"
    :title="state.errorMessage.title"
    :description="state.errorMessage.description"
  />
  <Article
    :title="state.content.title"
    width="full"
    v-if="state.content?.title && !state.isLoading && !state.errorMessage"
  >
    <ProjectPresentation :content="state.content.content" />
    <Client
      :client="{
        name: state.content.client.name,
        url: state.content.client.website,
        logo: state.content.client.logo.asset.url,
        description: state.content.client.descriptionRaw,
      }"
    />
  </Article>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import Client from "@/components/Client/Client.vue";
import PortfolioService from "@/services/portfolio";
import router from "@/router";
import ProjectPresentation from "@/components/ProjectPresentation/ProjectPresentation.vue";
import { GalleryItem } from "@/types/GalleryTypes";
import Error from "@/components/Error/Error.vue";

interface State {
  content: GalleryItem | null;
  isLoading: boolean;
  errorMessage: {
    title: string;
    description: string;
  } | null;
}

export default defineComponent({
  name: "page-project",
  setup() {
    const slug = router.currentRoute.value.params.slug as string;
    const state: State = reactive({
      content: null,
      isLoading: true,
      errorMessage: null,
    });

    PortfolioService.getPortfolioPiece(slug)
      .then((content) => {
        if (!content) {
          router.push("/404");
        }

        state.content = content;
      })
      .catch(() => {
        state.errorMessage = {
          title: "Oj da, dette skulle ikke skje",
          description: "Feilen er blitt rapportert, prøv igjen snart",
        };
        console.error(`Klarte ikke hente innholdet for ${slug}`);
      })
      .finally(() => {
        state.isLoading = false;
      });

    return {
      state,
    };
  },
  components: {
    Client,
    ProjectPresentation,
    Error,
  },
});
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
